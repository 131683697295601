import React from "react";

const FetchLoader = () => {
  return (
    <div>
      <div className="w-10 h-10 border-4 border-color mx-auto border-dashed rounded-full animate-spin"></div>
      <div className="text-center uppercase tracking-wider pt-2">
        Loading...
      </div>
    </div>
  );
};

export default FetchLoader;
