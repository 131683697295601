import { lazy } from "react";
import Gateways from "../pages/setting/gateways";
import SmsServices from "../pages/setting/sms-services";

const Logs = lazy(() => import("../pages/tdm/logs"));

const Host = lazy(() => import("../pages/host"));
const Banners = lazy(() => import("../pages/banners"));

// Leaderboard
const LeaderboardResult = lazy(() => import("../pages/leaderboardResult"));
const LeaderBoard = lazy(() => import("../pages/banners/leader board"));

// Battlepass & Features
const BattlePass = lazy(() => import("../pages/battlePass/passes"));
const Features = lazy(() => import("../pages/battlePass/features"));

// Staff & Roles
const Role = lazy(() => import("../pages/staff&role/role"));
const Staff = lazy(() => import("../pages/staff&role/staff"));

const ViewRoundLobby = lazy(() =>
  import("../pages/league/tournaments/ViewRoundLobby")
);

// Games
const Games = lazy(() => import("../pages/games"));
const AllGames = lazy(() => import("../pages/allGames"));

// Subscription
// const Subscriber = lazy(() => import("../pages/subscriber"));
// const Subscription = lazy(() => import("../pages/subscription"));

const ViewLeagueTour = lazy(() =>
  import("../pages/league/tournaments/ViewLeagueTour")
);
const Teams = lazy(() => import("../pages/teams"));
const Users = lazy(() => import("../pages/users/"));
const View = lazy(() => import("../pages/users/View"));
const Setting = lazy(() => import("../pages/setting"));
const Support = lazy(() => import("../pages/support"));
const Sponsor = lazy(() => import("../pages/sponsor"));
const Request = lazy(() => import("../pages/request"));
const Login = lazy(() => import("../pages/auth/Login"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Stages = lazy(() => import("../pages/league/stages"));
const GameTypes = lazy(() => import("../pages/games/types"));
const Tournaments = lazy(() => import("../pages/tournament"));
const ViewGame = lazy(() => import("../pages/games/ViewGame"));
const Transaction = lazy(() => import("../pages/transaction"));
const Profile = lazy(() => import("../pages/setting/profile"));
const Notification = lazy(() => import("../pages/notification"));
const ViewTour = lazy(() => import("../pages/tournament/ViewTour"));
const TDMPlayers = lazy(() => import("../pages/TDMPlayers/index"));
const GamePlayers = lazy(() => import("../pages/gamePlayers/index"));
const LeagueTournaments = lazy(() => import("../pages/league/tournaments"));
const TdmPlayersDetails = lazy(() =>
  import("../pages/TDMPlayers/TdmPlayersDetails")
);

const SavedTeam = lazy(() => import("../pages/savedTeam/index"));
const GamePlayersDetails = lazy(() =>
  import("../pages/gamePlayers/GamePlayerDetails")
);

const DailyMatches = lazy(() => import("../pages/dailyMatchList"));

// TDM
const Dispute = lazy(() => import("../pages/tdm/dispute"));
const BetAmount = lazy(() => import("../pages/tdm/betAmount"));
const TDMPlayersList = lazy(() => import("../pages/tdm/player-list"));
const TDMTransaction = lazy(() => import("../pages/tdm/transactions"));

// Reports
const GSTReports = lazy(() => import("../pages/reports/GSTReport"));
const TDSReports = lazy(() => import("../pages/reports/TDSReport"));
const CreditReports = lazy(() => import("../pages/reports/CreditReport"));

const AllRoutes = [
  { name: "Login", path: "/", element: <Login />, private: false },
  {
    name: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    private: true,
  },

  // Games
  { name: "Games", path: "/games", element: <Games />, private: true },
  {
    name: "AllGames",
    path: "/all/games",
    element: <AllGames />,
    private: true,
  },
  {
    name: "Game Types",
    path: "/games/types",
    element: <GameTypes />,
    private: true,
  },
  {
    name: "View Games",
    path: "/games/:slug",
    element: <ViewGame />,
    private: true,
  },

  // Banners
  {
    name: "Banners",
    path: "/banners",
    element: <Banners />,
    private: true,
  },

  // Tournaments
  {
    name: "View Tour",
    path: "/tournaments/view",
    element: <ViewTour />,
    private: true,
  },
  {
    name: "Tournaments",
    path: "/Tournaments",
    element: <Tournaments />,
    private: true,
  },

  // Daily Matches
  {
    name: "Daily Matches List",
    path: "/daily-matches",
    element: <DailyMatches />,
    private: true,
  },

  // Leagues
  {
    name: "League Stages",
    path: "/league/stages",
    element: <Stages />,
    private: true,
  },
  {
    name: "League Tournaments",
    path: "/league/tournaments",
    element: <LeagueTournaments />,
    private: true,
  },
  {
    name: "View League Tournaments",
    path: "/league/tournaments/:slug",
    element: <ViewLeagueTour />,
    private: true,
  },
  {
    name: "View League Tournaments Round Lobby",
    path: "/league/tournaments/:slug/:slug",
    element: <ViewRoundLobby />,
    private: true,
  },

  {
    name: "Users",
    path: "/users",
    element: <Users />,
    private: true,
  },
  {
    name: "User Profile",
    path: "/user-profile",
    element: <View />,
    private: true,
  },

  {
    name: "Teams",
    path: "/:user_id/teams",
    element: <Teams />,
    private: true,
  },
  {
    name: "TDM Challenges",
    path: "tdmPlayers",
    element: <TDMPlayers />,
    private: true,
  },
  {
    name: "View Games",
    path: "/tdm-player-details/:slug",
    element: <TdmPlayersDetails />,
    private: true,
  },
  {
    name: "GamePlayers",
    path: "/:user_id/gamePlayers",
    element: <GamePlayers />,
    private: true,
  },
  {
    name: "View Players",
    path: "/game-players-details/:slug",
    element: <GamePlayersDetails />,
    private: true,
  },
  // BetAmount
  // {
  //   name: "Bet Amount",
  //   path: "/bet-amount",
  //   element: <BetAmount />,
  //   private: true,
  // },
  {
    name: "Saved Team",
    path: "/saved-teams",
    element: <SavedTeam />,
    private: true,
  },

  {
    name: "Notifications",
    path: "/notifications",
    element: <Notification />,
    private: true,
  },

  {
    name: "Profile",
    path: "/setting/profile",
    element: <Profile />,
    private: true,
  },

  // Setting
  {
    name: "Setting",
    path: "/setting",
    element: <Setting />,
    private: true,
  },
  
  {
    name: "Manage SMS Services",
    path: "/manage-sms-services",
    element: <SmsServices />,
    private: true,
  },
  {
    name: "Manage Gateways",
    path: "/manage-gateways",
    element: <Gateways />,
    private: true,
  },
  {
    name: "Support",
    path: "/setting/support",
    element: <Support />,
    private: true,
  },
  {
    name: "Sponsor",
    path: "/setting/sponsor",
    element: <Sponsor />,
    private: true,
  },
  {
    name: "Host",
    path: "/setting/host",
    element: <Host />,
    private: true,
  },

  // Wallet
  {
    name: "Transaction",
    path: "/wallet/transactions",
    element: <Transaction />,
    private: true,
  },
  {
    name: "Withdraw requests",
    path: "/wallet/withdraw-requests",
    element: <Request />,
    private: true,
  },

  // Subscriptions
  // {
  //   name: "Subscription",
  //   path: "/subscriptions/plans",
  //   element: <Subscription />,
  //   private: true,
  // },
  // {
  //   name: "Subscriber",
  //   path: "/subscriptions/subscriber",
  //   element: <Subscriber />,
  //   private: true,
  // },

  // Leader Board
  {
    name: "LeaderBoard Banners",
    path: "/leader-board/banners",
    element: <LeaderBoard />,
    private: true,
  },
  {
    name: "Result",
    path: "/leaderboard",
    element: <LeaderboardResult />,
    private: true,
  },

  // Battle Pass
  {
    name: "Battle Pass",
    path: "/battle/passes",
    element: <BattlePass />,
    private: true,
  },
  {
    name: "Features",
    path: "/battle/features",
    element: <Features />,
    private: true,
  },

  // Staff & Role
  {
    name: "Staff",
    path: "/manage/staff",
    element: <Staff />,
    private: true,
  },
  {
    name: "Role",
    path: "/manage/roles",
    element: <Role />,
    private: true,
  },

  // TDM Section
  {
    name: "Bet Amount",
    path: "/tdm/bet-amounts",
    element: <BetAmount />,
    private: true,
  },
  {
    name: "TDM Transaction",
    path: "/tdm/transactions",
    element: <TDMTransaction />,
    private: true,
  },
  {
    name: "Dispute",
    path: "/tdm/disputes",
    element: <Dispute />,
    private: true,
  },
  {
    name: "Logs",
    path: "/tdm/logs",
    element: <Logs />,
    private: true,
  },
  {
    name: "Tdm Players List",
    path: "/tdm/players-list",
    element: <TDMPlayersList />,
    private: true,
  },

  // Reports
  {
    name: "GST Report",
    path: "/gst-reports/",
    element: <GSTReports />,
    private: true,
  },
  {
    name: "TDS Report",
    path: "/tds-reports/",
    element: <TDSReports />,
    private: true,
  },
  {
    name: "Credit/Debit Report",
    path: "/credit-debit-reports/",
    element: <CreditReports />,
    private: true,
  },
];

export default AllRoutes;
