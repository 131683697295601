import {
  FETCH_SMS_SERVICE,
  FETCH_SMS_SERVICE_FAIL,
  FETCH_SMS_SERVICE_SUCCESS,
  MANAGE_SMS_SERVICE,
  MANAGE_SMS_SERVICE_FAIL,
  MANAGE_SMS_SERVICE_SUCCESS,
} from ".";
import { manage_sms_services } from "../../utils/endpoints";
import { API_REQUEST } from "../../utils/interceptor";

// get gateways list
export const getSMSServiceList = () => async (dispatch) => {
  dispatch({ type: FETCH_SMS_SERVICE });
  try {
    const response = await API_REQUEST({
      url: manage_sms_services,
      method: "GET",
    });
    dispatch({ type: FETCH_SMS_SERVICE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_SMS_SERVICE_FAIL });
  }
};

// manage gateways
export const manageSMSServices = (props) => async (dispatch) => {
  const { method, name = null, callback, payload } = props;
  const getUrl = () => {
    switch (method) {
      case "POST":
        return manage_sms_services;

      case "PUT":
        return manage_sms_services + (name ? "/status/" : "/") + payload._id;

      case "DELETE":
        return manage_sms_services + "/" + payload._id;
    }
  };
  dispatch({ type: MANAGE_SMS_SERVICE });
  try {
    await API_REQUEST({
      url: getUrl(),
      method,
      data: payload,
    });
    callback && callback();
    dispatch(getSMSServiceList());
    dispatch({ type: MANAGE_SMS_SERVICE_SUCCESS });
  } catch (error) {
    dispatch({ type: MANAGE_SMS_SERVICE_FAIL });
  }
};
