import {
  SPONSOR_LIST,
  SPONSOR_LIST_FAIL,
  SPONSOR_LIST_SUCCESS,
  ADD_SPONSOR,
  ADD_SPONSOR_FAIL,
  ADD_SPONSOR_SUCCESS,
  UPDATE_SPONSOR,
  UPDATE_SPONSOR_FAIL,
  UPDATE_SPONSOR_SUCCESS,
  SUBSCRIPTION_LIST,
  SUBSCRIPTION_LIST_SUCCESS,
  SUBSCRIPTION_LIST_FAIL,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  HOST_LIST,
  HOST_LIST_SUCCESS,
  HOST_LIST_FAIL,
  BANNER_LIST,
  BANNER_LIST_SUCCESS,
  BANNER_LIST_FAIL,
  ADD_BANNER,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
} from "../actions";

const initialState = {
  loading: false,
  fetchLoading: false,

  sponsor: {},
  subscription: {},
  hosts: {},
  banners: {},
};

const sponsorReducer = (state = initialState, action) => {
  switch (action.type) {
    // SPONSOR_LIST
    case SPONSOR_LIST:
      return { ...state, fetchLoading: true };
    case SPONSOR_LIST_SUCCESS:
      return { ...state, sponsor: action.payload, fetchLoading: false };
    case SPONSOR_LIST_FAIL:
      return { ...state, fetchLoading: false };

    // HOST_LIST
    case HOST_LIST:
      return { ...state, fetchLoading: true };
    case HOST_LIST_SUCCESS:
      return { ...state, hosts: action.payload, fetchLoading: false };
    case HOST_LIST_FAIL:
      return { ...state, fetchLoading: false };

    // ADD_SPONSOR
    case ADD_SPONSOR:
      return { ...state, loading: true };
    case ADD_SPONSOR_SUCCESS:
      return { ...state, loading: false };
    case ADD_SPONSOR_FAIL:
      return { ...state, loading: false };

    // UPDATE_SPONSOR
    case UPDATE_SPONSOR:
      return { ...state, loading: true };
    case UPDATE_SPONSOR_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SPONSOR_FAIL:
      return { ...state, loading: false };

    // SUBSCRIPTION_LIST
    case SUBSCRIPTION_LIST:
      return { ...state, fetchLoading: true };
    case SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscription: action.payload.data,
        fetchLoading: false,
      };
    case SUBSCRIPTION_LIST_FAIL:
      return { ...state, fetchLoading: false };

    // ADD_SUBSCRIPTION
    case ADD_SUBSCRIPTION:
      return { ...state, loading: true };
    case ADD_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false };
    case ADD_SUBSCRIPTION_FAIL:
      return { ...state, loading: false };

    // UPDATE SUBSCRIPTION
    case UPDATE_SUBSCRIPTION:
      return { ...state, loading: true };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SUBSCRIPTION_FAIL:
      return { ...state, loading: false };

    // BANNER_LIST
    case BANNER_LIST:
      return { ...state, fetchLoading: true };
    case BANNER_LIST_SUCCESS:
      return { ...state, banners: action.payload.data, fetchLoading: false };
    case BANNER_LIST_FAIL:
      return { ...state, fetchLoading: false };

    // ADD_BANNER
    case ADD_BANNER:
      return { ...state, loading: true };
    case ADD_BANNER_SUCCESS:
      return { ...state, loading: false };
    case ADD_BANNER_FAIL:
      return { ...state, loading: false };

    // UPDATE_SUBSCRIPTION
    case UPDATE_SUBSCRIPTION:
      return { ...state, loading: true };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SUBSCRIPTION_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default sponsorReducer;
