import React, { memo, useState } from "react";

const TableImage = (props) => {
  const { bannerBool, src, className } = props;
  const [imgUrl, setimgUrl] = useState(src);
  const handleImageError = () => {
    setimgUrl(
      bannerBool
        ? "https://www.cityu.edu.hk/sklmp/sites/g/files/asqsls7251/files/default_images/dummy-post-horisontal.jpg"
        : "https://iconicentertainment.in/wp-content/uploads/2013/11/dummy-image-square.jpg"
    );
  };
  return (
    <div>
      {bannerBool ? (
        <img
          src={imgUrl}
          onError={handleImageError}
          style={{
            height: 80,
            width: 100,
            objectFit: "contain",
            objectPosition: "center",
          }}
          alt="img"
          className="rounded-md bg-gray-700"
        />
      ) : (
        <img
          onError={handleImageError}
          src={imgUrl}
          alt="img"
          className={`sm:w-11 bg-gray-700 sm:h-11 w-10 h-10 rounded-full object-cover object-center ${className}`}
        />
      )}
    </div>
  );
};

export default memo(TableImage);
