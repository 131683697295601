import {
  FETCH_REPORTS,
  FETCH_REPORTS_FAIL,
  FETCH_REPORTS_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
};

const txnReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS:
      return { ...state, fetchLoading: true };
    case FETCH_REPORTS_SUCCESS:
      return { ...state, fetchLoading: false, ...action.payload };
    case FETCH_REPORTS_FAIL:
      return { ...state, fetchLoading: false };

    default:
      return state;
  }
};

export default txnReportReducer;
