import {
  FETCH_SMS_SERVICE,
  FETCH_SMS_SERVICE_FAIL,
  FETCH_SMS_SERVICE_SUCCESS,
  MANAGE_GATEWAYS,
  MANAGE_GATEWAYS_FAIL,
  MANAGE_GATEWAYS_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  loading: false,
  smsSerData: [],
};

const smsServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SMS_SERVICE:
      return { ...state, fetchLoading: true };
    case FETCH_SMS_SERVICE_SUCCESS:
      return { ...state, fetchLoading: false, smsServiceData: action.payload };
    case FETCH_SMS_SERVICE_FAIL:
      return { ...state, fetchLoading: false };

    case MANAGE_GATEWAYS:
      return { ...state, loading: true };
    case MANAGE_GATEWAYS_SUCCESS:
      return { ...state, loading: false };
    case MANAGE_GATEWAYS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default smsServiceReducer;
