import {
  CREATE_BATTLEPASS,
  CREATE_BATTLEPASS_FAIL,
  CREATE_BATTLEPASS_SUCCESS,
  GET_BATTLEPASS_LIST,
  GET_BATTLEPASS_LIST_FAIL,
  GET_BATTLEPASS_LIST_SUCCESS,
  REMOVE_BATTLEPASS,
  REMOVE_BATTLEPASS_FAIL,
  REMOVE_BATTLEPASS_SUCCESS,
  UPDATE_BATTLEPASS,
  UPDATE_BATTLEPASS_FAIL,
  UPDATE_BATTLEPASS_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  feature: {},
  pass: {},
  loading: false,
};

const battlePassReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BATTLEPASS_LIST:
      return { ...state, fetchLoading: true };
    case GET_BATTLEPASS_LIST_SUCCESS:
      return { ...state, fetchLoading: false, ...action.payload };
    case GET_BATTLEPASS_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case CREATE_BATTLEPASS:
      return { ...state, loading: true };
    case CREATE_BATTLEPASS_SUCCESS:
      return { ...state, loading: false };
    case CREATE_BATTLEPASS_FAIL:
      return { ...state, loading: false };

    case REMOVE_BATTLEPASS:
      return { ...state, loading: true };
    case REMOVE_BATTLEPASS_SUCCESS:
      return { ...state, loading: false };
    case REMOVE_BATTLEPASS_FAIL:
      return { ...state, loading: false };

    case UPDATE_BATTLEPASS:
      return { ...state, loading: true };
    case UPDATE_BATTLEPASS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_BATTLEPASS_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default battlePassReducer;
