import axios from "axios";
import {
  ADD_ROUND_GROUP,
  ADD_ROUND_GROUP_FAIL,
  ADD_ROUND_GROUP_SUCCESS,
  ADD_STAGE_ROUND,
  ADD_STAGE_ROUND_FAIL,
  ADD_STAGE_ROUND_SUCCESS,
  CREATE_LEAGUE_TOUR,
  CREATE_LEAGUE_TOUR_FAIL,
  CREATE_LEAGUE_TOUR_SUCCESS,
  CREATE_STAGE,
  CREATE_STAGE_FAIL,
  CREATE_STAGE_SUCCESS,
  DELETE_STAGE_ROUND,
  DELETE_STAGE_ROUND_FAIL,
  DELETE_STAGE_ROUND_SUCCESS,
  DELETE_TOUR_STAGE,
  DELETE_TOUR_STAGE_FAIL,
  DELETE_TOUR_STAGE_SUCCESS,
  GET_LEAGIE_TOUR_LIST,
  GET_LEAGIE_TOUR_LIST_FAIL,
  GET_LEAGIE_TOUR_LIST_SUCCESS,
  GET_LEAGIE_TOUR_TEAM_LIST,
  GET_LEAGIE_TOUR_TEAM_LIST_FAIL,
  GET_LEAGIE_TOUR_TEAM_LIST_SUCCESS,
  GET_STAGE_LIST,
  GET_STAGE_LIST_FAIL,
  GET_STAGE_LIST_SUCCESS,
  GET_STAGE_ROUND,
  GET_STAGE_ROUND_FAIL,
  GET_STAGE_ROUND_SUCCESS,
  GET_TEAMS_STAGE_WISE,
  GET_TEAMS_STAGE_WISE_FAIL,
  GET_TEAMS_STAGE_WISE_SUCCESS,
  GET_TOUR_STAGE,
  GET_TOUR_STAGE_FAIL,
  GET_TOUR_STAGE_SUCCESS,
  JOINED_DELETE_TEAM,
  JOINED_DELETE_TEAM_FAIL,
  JOINED_DELETE_TEAM_SUCCESS,
  LEAGUE_TOUR_REMOVE,
  LEAGUE_TOUR_REMOVE_FAIL,
  LEAGUE_TOUR_REMOVE_SUCCESS,
  LEAGUE_TOUR_STATUS_UPDATE,
  LEAGUE_TOUR_STATUS_UPDATE_FAIL,
  LEAGUE_TOUR_STATUS_UPDATE_SUCCESS,
  STAGE_REMOVE,
  STAGE_REMOVE_FAIL,
  STAGE_REMOVE_SUCCESS,
  STAGE_RESULT,
  STAGE_RESULT_FAIL,
  STAGE_RESULT_SUCCESS,
  STAGE_STATUS_UPDATE,
  STAGE_STATUS_UPDATE_FAIL,
  STAGE_STATUS_UPDATE_SUCCESS,
  UPDATE_LEAGUE_TOUR,
  UPDATE_LEAGUE_TOUR_FAIL,
  UPDATE_LEAGUE_TOUR_SUCCESS,
  UPDATE_LOBBY_RESULT,
  UPDATE_LOBBY_RESULT_FAIL,
  UPDATE_LOBBY_RESULT_SUCCESS,
  UPDATE_LOBBY_STATUS,
  UPDATE_LOBBY_STATUS_FAIL,
  UPDATE_LOBBY_STATUS_SUCCESS,
  UPDATE_STAGE,
  UPDATE_STAGE_FAIL,
  UPDATE_STAGE_SUCCESS,
  UPDATE_TEAM_RANK,
  UPDATE_TEAM_RANK_FAIL,
  UPDATE_TEAM_RANK_SUCCESS,
  UPDATE_TOUR_STAGE,
  UPDATE_TOUR_STAGE_FAIL,
  UPDATE_TOUR_STAGE_SUCCESS,
  VIEW_LEAGUE_TOUR,
  VIEW_LEAGUE_TOUR_FAIL,
  VIEW_LEAGUE_TOUR_SUCCESS,
  VIEW_ROUND_GROUP,
  VIEW_ROUND_GROUP_FAIL,
  VIEW_ROUND_GROUP_SUCCESS,
} from ".";
import {
  create_round_group,
  create_stage,
  declare_slots_lobby,
  delete_league_team,
  delete_league_tour_stage,
  delete_round_group,
  delete_stages,
  export_league_tour_teams,
  get_stage_round_list,
  get_stages_list,
  league_tour_create,
  league_tour_delete,
  league_tour_list,
  league_tour_running_status,
  league_tour_stage,
  league_tour_status,
  league_tour_teams,
  league_tour_update,
  league_tour_view,
  round_running_status,
  stage_amount_settle,
  stage_result_status,
  update_league_tour_stage,
  update_round_group,
  update_stage_status,
  update_stages,
} from "../../utils/endpoints";
import toast from "react-hot-toast";
import { API_REQUEST, errorHandler } from "../../utils/interceptor";

// ------------------------ Stages
// stages lists
export const getStages =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_STAGE_LIST });
    try {
      const response = await axios.get(get_stages_list, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      dispatch({ type: GET_STAGE_LIST_SUCCESS, payload: response?.data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: GET_STAGE_LIST_FAIL });
    }
  };

// Remove Status
export const deleteStage = (stage_id, callBack) => async (dispatch) => {
  dispatch({ type: STAGE_REMOVE });
  try {
    const response = await axios.get(`${delete_stages}/${stage_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: STAGE_REMOVE_SUCCESS });
    dispatch(getStages());
    toast.success(message);
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: STAGE_REMOVE_FAIL });
    callBack();
  }
};

// Stage Status Update
export const updateStageStatus = (stage_id, payload) => async (dispatch) => {
  dispatch({ type: STAGE_STATUS_UPDATE });
  try {
    await axios.post(`${update_stage_status}/${stage_id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    dispatch(getStages());
    dispatch({ type: STAGE_STATUS_UPDATE_SUCCESS });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: STAGE_STATUS_UPDATE_FAIL });
  }
};

// Create Stage
export const addStage = (payload, callBack) => async (dispatch) => {
  dispatch({ type: CREATE_STAGE });
  try {
    const response = await axios.post(create_stage, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { message } = response.data;
    dispatch({ type: CREATE_STAGE_SUCCESS });
    dispatch(getStages());
    toast.success(message);

    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CREATE_STAGE_FAIL });
  }
};

// update stage
export const updateStage =
  (stage_id, payload, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_STAGE });
    try {
      const response = await axios.post(
        `${update_stages}/${stage_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: UPDATE_STAGE_SUCCESS });
      dispatch(getStages());
      toast.success(message);

      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_STAGE_FAIL });
    }
  };

// ------------------------ League Tournaments
export const getLeagueTour =
  (params = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_LEAGIE_TOUR_LIST });
    try {
      const response = await axios.get(league_tour_list, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      const { data } = response.data;
      // if (data?.results?.length === 0) {
      //   // toast.error("No Record Found!");
      // }
      dispatch({ type: GET_LEAGIE_TOUR_LIST_SUCCESS, payload: data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: GET_LEAGIE_TOUR_LIST_FAIL });
    }
  };

export const getLeagueTourTeams =
  (params = {}, id) =>
  async (dispatch) => {
    dispatch({ type: GET_LEAGIE_TOUR_TEAM_LIST });
    try {
      const response = await axios.get(`${league_tour_teams}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      });
      const { data } = response.data;
      if (data?.results?.length === 0) {
        // toast.error("No Record Found!");
        dispatch({
          type: GET_LEAGIE_TOUR_TEAM_LIST_SUCCESS,
          payload: { result: [], paginnation: {} },
        });
      } else {
        dispatch({ type: GET_LEAGIE_TOUR_TEAM_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      errorHandler(error);
      dispatch({
        type: GET_LEAGIE_TOUR_TEAM_LIST_FAIL,
        payload: {
          result: [],
          paginnation: {},
        },
      });
    }
  };

export const exportLeagueTourTeams = (id) => async () => {
  try {
    const response = await API_REQUEST({
      url: export_league_tour_teams + "/" + id,
      method: "GET",
    });
    const a = document.createElement("a");
    a.href = response.file;
    a.download = "exportLeagueTourTeams.csv";
    a.click();
  } catch (error) {
    // errorHandler(error);
  }
};

// delete team
export const deleteLeagueTeam = (payload, callBack) => async (dispatch) => {
  dispatch({ type: JOINED_DELETE_TEAM });
  try {
    const response = await axios.get(
      `${delete_league_team}/${payload.leagueTourId}/${payload.teamId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      }
    );
    const { message } = response.data;
    dispatch({ type: JOINED_DELETE_TEAM_SUCCESS });
    // dispatch(viewTour())
    toast.success(message);
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: JOINED_DELETE_TEAM_FAIL });
  }
};

// add tournament
export const addLeagueTournament =
  (payload, callBack, params = {}) =>
  async (dispatch) => {
    dispatch({ type: CREATE_LEAGUE_TOUR });
    try {
      const response = await axios.post(league_tour_create, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      const { message } = response.data;
      dispatch({ type: CREATE_LEAGUE_TOUR_SUCCESS });
      toast.success(message);
      dispatch(getLeagueTour(params));
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: CREATE_LEAGUE_TOUR_FAIL });
    }
  };

// update tournament
export const updateLeagueTour =
  (tour_id, payload, callBack, params = {}) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_LEAGUE_TOUR });
    try {
      const response = await axios.post(
        `${league_tour_update}/${tour_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: UPDATE_LEAGUE_TOUR_SUCCESS });
      dispatch(getLeagueTour(params));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_LEAGUE_TOUR_FAIL });
    }
  };

// update tournament status
export const updateLeagueTourStatus =
  (tour_id, payload, params = {}) =>
  async (dispatch) => {
    dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE });
    try {
      await axios.post(`${league_tour_status}/${tour_id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      // const { message } = response.data;
      dispatch(getLeagueTour(params));
      dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE_SUCCESS });
      // toast.success(message);
    } catch (error) {
      errorHandler(error);
      dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE_FAIL });
    }
  };

// delete tournament
export const deleteLeagueTournament =
  (tour_id, callBack, params) => async (dispatch) => {
    dispatch({ type: LEAGUE_TOUR_REMOVE });
    try {
      const response = await axios.get(`${league_tour_delete}/${tour_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      const { message } = response.data;
      dispatch({ type: LEAGUE_TOUR_REMOVE_SUCCESS });
      dispatch(getLeagueTour(params));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: LEAGUE_TOUR_REMOVE_FAIL });
    }
  };

// update tournament running status
export const updateLeagurTourRunningStatus =
  (tour_id, payload, callBack, params = {}) =>
  async (dispatch) => {
    dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE });
    try {
      const response = await axios.post(
        `${league_tour_running_status}/${tour_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE_SUCCESS });
      dispatch(getLeagueTour(params));
      toast.success(message);

      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: LEAGUE_TOUR_STATUS_UPDATE_FAIL });
    }
  };

// view tournament
export const viewLeaguTournament = (tour_id) => async (dispatch) => {
  dispatch({ type: VIEW_LEAGUE_TOUR });
  try {
    const response = await axios.get(`${league_tour_view}/${tour_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: VIEW_LEAGUE_TOUR_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: VIEW_LEAGUE_TOUR_FAIL });
  }
};

// view tournament
export const getTourStages = (tour_id) => async (dispatch) => {
  dispatch({ type: GET_TOUR_STAGE });
  try {
    const response = await axios.get(`${league_tour_stage}/${tour_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    const { data } = response.data;
    dispatch({ type: GET_TOUR_STAGE_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: GET_TOUR_STAGE_FAIL });
  }
};

// update league tournaments
export const updateTourStages =
  (tour_id, payload, callBack, stage_id) => async (dispatch) => {
    dispatch({ type: UPDATE_TOUR_STAGE });
    try {
      const response = await axios.post(
        stage_id
          ? `${update_league_tour_stage}/${tour_id}/stages/${stage_id}`
          : `${update_league_tour_stage}/${tour_id}/stages`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      callBack();
      const { message, status } = response.data;
      dispatch({ type: UPDATE_TOUR_STAGE_SUCCESS });
      dispatch(viewLeaguTournament(tour_id));
      dispatch(getTourStages(tour_id));
      status ? toast.success(message) : toast.error(message);
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_TOUR_STAGE_FAIL });
    }
  };

// Delete League Tournmaent Stage
export const deleteTourStage = (stage_id, callBack) => async (dispatch) => {
  dispatch({ type: DELETE_TOUR_STAGE });
  try {
    const response = await axios.get(
      `${delete_league_tour_stage}/${stage_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      }
    );
    const { message } = response.data;
    dispatch({ type: DELETE_TOUR_STAGE_SUCCESS });
    // dispatch(viewLeaguTournament(tour_id));
    // dispatch(getTourStages(tour_id));
    toast.success(message);
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: DELETE_TOUR_STAGE_FAIL });
  }
};

// Stage Rounds
export const getStageRounds = (tour_id, stage_id) => async (dispatch) => {
  dispatch({ type: GET_STAGE_ROUND });
  try {
    const response = await axios.get(
      `${get_stage_round_list}/${tour_id}/stages/${stage_id}/rounds`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      }
    );
    const { data } = response.data;
    dispatch({ type: GET_STAGE_ROUND_SUCCESS, payload: data });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: GET_STAGE_ROUND_FAIL });
  }
};

// settle amoount stage
export const settleStageAmount =
  (tourStageId, callBack) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${stage_amount_settle}/${tourStageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message, status } = response.data;
      status ? toast.success(message) : toast.error(message);
      callBack();
    } catch (error) {
      errorHandler(error);
    }
  };

export const getTeamsStageWise =
  (stage_id, params = {}, isPromotedTeams = false) =>
  async (dispatch) => {
    dispatch({ type: GET_TEAMS_STAGE_WISE });
    try {
      const response = await axios.get(
        `${get_stage_round_list}/tournament-stage/${stage_id}/${
          isPromotedTeams ? "get-promoted-teams" : "get-teams"
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
          params,
        }
      );
      const { data } = response.data;
      dispatch({ type: GET_TEAMS_STAGE_WISE_SUCCESS, payload: data });
    } catch (error) {
      errorHandler(error);
      dispatch({ type: GET_TEAMS_STAGE_WISE_FAIL });
    }
  };

export const createNextStageTeams =
  (payload, roundId, callBack) => async (dispatch) => {
    dispatch({ type: GET_TEAMS_STAGE_WISE });
    try {
      const response = await axios.post(
        `${get_stage_round_list}/stages/rounds/${roundId}/create-group`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { data } = response.data;
      // dispatch({ type: GET_TEAMS_STAGE_WISE_SUCCESS, payload: data });

      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: GET_TEAMS_STAGE_WISE_FAIL });
    }
  };

export const AddStageRound =
  (tour_id, stage_id, payload, callBack) => async (dispatch) => {
    dispatch({ type: ADD_STAGE_ROUND });
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      };
      const response = await axios.post(
        `${get_stage_round_list}/${tour_id}/stages/${stage_id}/create-round`,
        payload,
        headers
      );
      const { message } = response.data;

      dispatch({ type: ADD_STAGE_ROUND_SUCCESS });
      // dispatch(viewLeaguTournament(tour_id));
      dispatch(getStageRounds(tour_id, stage_id));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: ADD_STAGE_ROUND_FAIL });
    }
  };

export const deleteStageRound =
  (tour_id, round_id, callBack) => async (dispatch) => {
    dispatch({ type: DELETE_STAGE_ROUND });
    try {
      const response = await axios.get(
        `${get_stage_round_list}/stages/${round_id}/delete-round`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: DELETE_STAGE_ROUND_SUCCESS });
      // dispatch(viewLeaguTournament(tour_id));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: DELETE_STAGE_ROUND_FAIL });
    }
  };

export const updateStageRound =
  (round_id, payload, callBack) => async (dispatch) => {
    dispatch({ type: ADD_STAGE_ROUND });
    try {
      const response = await axios.post(
        `${get_stage_round_list}/stages/${round_id}/update-round`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: ADD_STAGE_ROUND_SUCCESS });
      // dispatch(viewLeaguTournament(tour_id));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: ADD_STAGE_ROUND_FAIL });
    }
  };

// Add Group for round
export const AddroundGroup =
  (roundId, payload, callBack, isFirstRound) => async (dispatch) => {
    dispatch({ type: ADD_ROUND_GROUP });
    try {
      const response = await axios.post(
        isFirstRound
          ? `${create_round_group}/${roundId}/create-group/automated`
          : `${create_round_group}/${roundId}/create-group`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message, status } = response.data;

      dispatch({ type: ADD_ROUND_GROUP_SUCCESS });
      dispatch(getroundGroup(roundId));
      status ? toast.success(message) : toast.error(message);
      status && callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: ADD_ROUND_GROUP_FAIL });
    }
  };

export const getroundGroup = (roundId, params) => async (dispatch) => {
  dispatch({ type: ADD_ROUND_GROUP });
  try {
    const response = await axios.get(
      `${create_round_group}/${roundId}/groups`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        params,
      }
    );

    dispatch({ type: ADD_ROUND_GROUP_SUCCESS, payload: response?.data });
    // dispatch(viewLeaguTournament(tour_id));
    // dispatch(getStageRounds(tour_id, stage_id));
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ADD_ROUND_GROUP_FAIL, payload: { data: [] } });
  }
};

// Declare Stage Result Status
export const declareStageResult =
  (tournamentStageId, callBack, teams = []) =>
  async (dispatch) => {
    dispatch({ type: STAGE_RESULT });
    const payload = {
      stagesResultStatus: 1,
      teams,
    };
    try {
      const { data } = await axios.post(
        `${stage_result_status}/${tournamentStageId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      toast.success(data?.message);
      dispatch({ type: STAGE_RESULT_SUCCESS });
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: STAGE_RESULT_FAIL, payload: { data: [] } });
    }
  };

// Update round running status
export const updateRoundRunningStatus =
  (roundID, callBack) => async (dispatch) => {
    dispatch({ type: STAGE_RESULT });
    const payload = {
      runningStatus: true,
    };
    try {
      const { data } = await axios.post(
        `${round_running_status}/${roundID}/update-running-status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      toast.success(data.message);
      dispatch({ type: STAGE_RESULT_SUCCESS });
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: STAGE_RESULT_FAIL, payload: { data: [] } });
    }
  };

export const updateroundGroup =
  (lobbyId, roundId, payload, callBack) => async (dispatch) => {
    dispatch({ type: ADD_ROUND_GROUP });
    try {
      await axios.post(`${update_round_group}/${lobbyId}/date-time`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });

      dispatch(getroundGroup(roundId));
      callBack();
      // dispatch(viewLeaguTournament(tour_id));
      // dispatch(getStageRounds(tour_id, stage_id));
    } catch (error) {
      errorHandler(error);
      dispatch({ type: ADD_ROUND_GROUP_FAIL, payload: { data: [] } });
    }
  };

export const viewroundGroup = (lobbyId) => async (dispatch) => {
  dispatch({ type: VIEW_ROUND_GROUP });
  try {
    const res = await axios.get(`${create_round_group}/${lobbyId}/view-lobby`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    dispatch({ type: VIEW_ROUND_GROUP_SUCCESS, payload: res?.data });
    // dispatch(viewLeaguTournament(tour_id));
    // dispatch(getStageRounds(tour_id, stage_id));
  } catch (error) {
    errorHandler(error);
    dispatch({ type: VIEW_ROUND_GROUP_FAIL, payload: { data: [] } });
  }
};

export const declareSlotInLobby =
  (lobby_id, payload, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_TEAM_RANK });
    try {
      const response = await axios.post(
        `${declare_slots_lobby}/${lobby_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const { message } = response.data;
      dispatch({ type: UPDATE_TEAM_RANK_SUCCESS });
      dispatch(viewroundGroup(lobby_id));
      toast.success(message);
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_TEAM_RANK_FAIL });
    }
  };

export const updateLobbyStatus =
  (lobbyId, payload, roundId, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_LOBBY_STATUS });
    try {
      await axios.post(
        `${update_round_group}/${lobbyId}/running-status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      dispatch(getroundGroup(roundId));
      callBack();
      dispatch({ type: UPDATE_LOBBY_STATUS_SUCCESS });
      // dispatch(viewLeaguTournament(tour_id));
      // dispatch(getStageRounds(tour_id, stage_id));
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_LOBBY_STATUS_FAIL, payload: { data: [] } });
    }
  };

export const deleteLobby = (lobbyId, roundId, callBack) => async (dispatch) => {
  dispatch({ type: VIEW_ROUND_GROUP });
  try {
    const response = await axios.get(
      `${delete_round_group}/${lobbyId}/delete-group`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      }
    );
    toast.success(response?.data?.message);
    dispatch(getroundGroup(roundId));
    callBack();
  } catch (error) {
    errorHandler(error);
    dispatch({ type: VIEW_ROUND_GROUP_FAIL, payload: { data: [] } });
  }
};

export const updateLobbyTeamsStatus =
  (lobbyId, payload, callBack) => async (dispatch) => {
    dispatch({ type: VIEW_ROUND_GROUP });
    try {
      const response = await axios.post(
        `${update_round_group}/${lobbyId}/promoted-status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      callBack();
      toast.success(response?.data?.message);
      dispatch(viewroundGroup(lobbyId));
      // dispatch(viewLeaguTournament(tour_id));
      // dispatch(getStageRounds(tour_id, stage_id));
    } catch (error) {
      errorHandler(error);
      dispatch({ type: VIEW_ROUND_GROUP_FAIL, payload: { data: [] } });
    }
  };

export const createGroupForNextStage =
  (lobbyId, payload, callBack) => async (dispatch) => {
    dispatch({ type: VIEW_ROUND_GROUP });
    try {
      const response = await axios.post(
        `${create_round_group}/${lobbyId}/create-group`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      callBack();
      toast.success(response?.data?.message);
      // dispatch(viewLeaguTournament(tour_id));
      // dispatch(getStageRounds(tour_id, stage_id));
    } catch (error) {
      errorHandler(error);
      dispatch({ type: VIEW_ROUND_GROUP_FAIL, payload: { data: [] } });
    }
  };

export const updateLobbyDetails = (lobbyId, payload) => async (dispatch) => {
  dispatch({ type: VIEW_ROUND_GROUP });
  try {
    await axios.post(`${update_round_group}/${lobbyId}/room-details`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    });
    dispatch(viewroundGroup(lobbyId));
  } catch (error) {
    errorHandler(error);
    dispatch({ type: VIEW_ROUND_GROUP_FAIL, payload: { data: [] } });
  }
};

export const updateLobbyRank =
  (lobbyId, payload, callBack) => async (dispatch) => {
    dispatch({ type: UPDATE_LOBBY_RESULT });
    try {
      await axios.post(`${update_round_group}/${lobbyId}/lobby-rank`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      });
      // dispatch(viewroundGroup(lobbyId));
      dispatch({ type: UPDATE_LOBBY_RESULT_SUCCESS });
      dispatch(viewroundGroup(lobbyId));
      callBack();
    } catch (error) {
      errorHandler(error);
      dispatch({ type: UPDATE_LOBBY_RESULT_FAIL });
    }
  };
