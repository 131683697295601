import React, { useEffect } from "react";
import { staffRole } from "../layouts/FindPermission";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { viewPermissions } from "../redux/actions/staffRoleAction";

const PrivateRoute = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { paths } = useSelector((state) => state.authReducer);

  let isAuth = localStorage.getItem("AccessToken");
  let isAllowRoute =
    staffRole && paths.length > 0 ? paths.includes(pathname) : true;

  useEffect(() => {
    const { staffRole } = JSON.parse(localStorage.getItem("Admin"));
    if (staffRole) {
      dispatch(viewPermissions(staffRole?._id));
    }
  }, [pathname]);

  return isAuth ? (
    isAllowRoute ? (
      <Outlet />
    ) : (
      <Navigate to={"/dashboard"} />
    )
  ) : (
    <Navigate to={"/"} />
  );
};

export default PrivateRoute;
