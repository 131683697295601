import { useDispatch, useSelector } from "react-redux";
import { setPathsToCheck } from "../redux/actions/authAction";
export const { staffRole } = localStorage.getItem("Admin")
  ? JSON.parse(localStorage.getItem("Admin"))
  : { staffRole: {} };

export const IsActToRolePermitted = (resource, action) => {
  const { allPermissions } = useSelector((state) => state.staffRoleReducer);
  const allActions = allPermissions.filter((item) => item.resource == resource);
  return staffRole ? allActions?.[0]?.actions?.includes(action) : true;
};

const FindPermission = (item) => {
  const dispatch = useDispatch();
  const { heading } = item;
  const { allPermissions } = useSelector((state) => state.staffRoleReducer);
  const isPermitted = allPermissions.find(
    (item) => item.resource == heading?.toLowerCase()
  );

  const getPathAccess = () => {
    if (item.path) {
      dispatch(setPathsToCheck(item.path));
      // heading === "tournament" &&
      //   dispatch(setPathsToCheck("/tournaments/view"));
    }
    // Check if the object has a menus property
    if (item.menus) {
      item.menus.forEach((menu) => {
        if (menu.path) {
          menu.path !== "/daily-matches" && dispatch(setPathsToCheck(menu.path));
          heading === "tournament" &&
            dispatch(setPathsToCheck("/tournaments/view"));
        }
      });
    }
  };
  isPermitted && isPermitted?.actions?.length > 0 && getPathAccess();

  return staffRole
    ? isPermitted
      ? isPermitted?.actions?.length > 0
      : false
    : true;
};

export default FindPermission;
