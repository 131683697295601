import { combineReducers } from "redux";
import authReducer from "./authReducer";
import gameReducer from "./gameReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import tournamentReducer from "./tournamentReducer";
import sponsorReducer from "./sponsorReducer";
import leagueReducer from "./leagueReducer";
import leaderboardReducer from "./leaderboardReducer";
import battlePassReducer from "./passReducer";
import staffRoleReducer from "./staffRoleReducer";
import tdmReducer from "./tdmReducer";
import txnReportReducer from "./reportReducer";
import gatewaysReducer from "./gatewayesReducer";
import smsServiceReducer from "./smsServiceReducer";

const rootReducer = combineReducers({
  authReducer,
  tournamentReducer,
  gameReducer,
  userReducer,
  notificationReducer,
  sponsorReducer,
  leagueReducer,
  leaderboardReducer,
  battlePassReducer,
  staffRoleReducer,
  tdmReducer,
  txnReportReducer,
  gatewaysReducer,
  smsServiceReducer,
});

export default rootReducer;
