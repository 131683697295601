import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (Wcomponent) => {
  return function Component() {
    const [openSidebar, setOpenSidebar] = useState(true);

    // handleToggle
    const handleToggle = () => setOpenSidebar(!openSidebar);
    return (
      <div className="flex h-screen relative overflow-hidden">
        {/* Sidebar */}
        <div
          className={`w-80 lg:w-1/4 xl:w-1/5 ${
            !openSidebar ? "left-0 lg:hidden w-0" : "-left-full "
          } bg-secondary shadow-md lg:static absolute translate-all ease-in-out duration-300 top-0 z-50 lg:z-auto h-full`}
        >
          <Sidebar handleToggle={handleToggle} toggle={openSidebar} />
        </div>

        <main
          className={`h-full pb-5 overflow-auto ${
            !openSidebar ? "w-full" : "w-full lg:w-3/4 xl:w-4/5"
          }`}
        >
          <div className="sm:px-5 sm:py-4 p-3 table-head sticky z-40 w-full top-0 left-0">
            <Header handleToggle={handleToggle} toggle={openSidebar} />
          </div>
          <div id="component" className="sm:px-5 px-3 py-1">
            <Wcomponent />
          </div>
        </main>
      </div>
    );
  };
};

export default Layout;
