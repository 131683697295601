import {
  FETCH_GATEWAYS,
  FETCH_GATEWAYS_FAIL,
  FETCH_GATEWAYS_SUCCESS,
  FETCH_GATEWAYS_TYPES,
  FETCH_GATEWAYS_TYPES_FAIL,
  FETCH_GATEWAYS_TYPES_SUCCESS,
  MANAGE_GATEWAYS,
  MANAGE_GATEWAYS_FAIL,
  MANAGE_GATEWAYS_SUCCESS,
} from ".";
import { gateway_type_list, manage_payment_gatways } from "../../utils/endpoints";
import { API_REQUEST } from "../../utils/interceptor";

// get gateways list
export const getGatewaysList = () => async (dispatch) => {
  dispatch({ type: FETCH_GATEWAYS });
  try {
    const response = await API_REQUEST({
      url: manage_payment_gatways,
      method: "GET",
    });
    dispatch({ type: FETCH_GATEWAYS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_GATEWAYS_FAIL });
  }
};

// manage gateways
export const manageGateways = (props) => async (dispatch) => {
  const { method, name = null, callback, payload } = props;
  const getUrl = () => {
    switch (method) {
      case "POST":
        return manage_payment_gatways;

      case "PUT":
        return manage_payment_gatways + (name ? "/status/" : "/") + payload._id;

      case "DELETE":
        return manage_payment_gatways + "/" + payload._id;
    }
  };
  dispatch({ type: MANAGE_GATEWAYS });
  try {
    await API_REQUEST({
      url: getUrl(),
      method,
      data: payload,
    });
    callback && callback();
    dispatch(getGatewaysList());
    dispatch({ type: MANAGE_GATEWAYS_SUCCESS });
  } catch (error) {
    dispatch({ type: MANAGE_GATEWAYS_FAIL });
  }
};


// fetch gateway types
export const fetchGatewayTypes = () => async (dispatch) => {
  dispatch({ type: FETCH_GATEWAYS_TYPES });
  try {
    const response = await API_REQUEST({
      url: gateway_type_list,
      method: "GET",
    });
    dispatch({
      type: FETCH_GATEWAYS_TYPES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_GATEWAYS_TYPES_FAIL });
  }
};
