import axios from "axios";
import toast from "react-hot-toast";

// api request handler
export const API_REQUEST = async (props) => {
  const { url, method, data, headers, params } = props;
  const accessToken = localStorage.getItem("AccessToken");
  const requestOptions = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
    params: method === "GET" ? params : undefined,
    data: method !== "GET" ? data : undefined,
  };

  try {
    const response = await axios(requestOptions);
    method !== "GET" &&
      (response?.data?.status
        ? toast.success(response?.data?.message)
        : toast.error(response?.data?.message));
    return response?.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status == 401) {
        localStorage.clear();
        toast.error(error.response.data.message);
      } else {
        console.log(error.response);
        // The request was made and the server responded with a status code
        toast.error(
          error.response.data.message ||
            `HTTP error! Status: ${error.response.status}`
        );
        // toast.error(`HTTP error! Status: ${error.response.status}`);
        throw new Error(`HTTP error! Status: ${error.response.status}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      toast.error("No response received from server");
      throw new Error("No response received from server");
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error("Error:", error.message);
      throw new Error(error.message);
    }
  }
};

// error handler to prevois actions before create API_REQUEST
export const errorHandler = (error) => {
  if (error?.response) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      toast.error(error?.response?.data?.message);
    } else if (error.response.status === 500) {
      toast.error(error?.response?.data?.message || "Server error");
    } else {
      toast.error(
        error?.response?.data?.message ||
          `HTTP error! Status: ${error?.response?.status}`
      );
      throw new Error(`HTTP error! Status: ${error?.response?.status}`);
    }
  } else if (error.request) {
    // The request was made but no response was received
    toast.error("No response received from server");
    throw new Error("No response received from server");
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error("Error:", error.message);
    throw new Error(error.message);
  }
};
