import React, { useEffect, useState } from "react";
import Layout from "../../../layouts";
import { useDispatch, useSelector } from "react-redux";
import {
  getGatewaysList,
  manageGateways,
} from "../../../redux/actions/gatewaysAction";
import Heading from "../../../components/Heading";
import FetchLoader from "../../../components/FetchLoader";
import TableImage from "../../../components/TableImage";
import Toggle from "../../../components/Toggle";
import NoData from "../../../components/NoData";
import ShowOption from "../../../components/ShowOption";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import NewGateway from "./NewGateway";
import ConfrimationModal from "../../../components/ConfrimationModal";

const Gateways = () => {
  const dispatch = useDispatch();
  const [modals, setModals] = useState({
    formModal: false,
    deleteModal: false,
    skeleton: false,
  });
  const [editData, setEditData] = useState();
  const { fetchLoading, loading, gatawaysData } = useSelector(
    (state) => state.gatewaysReducer
  );

  // handle Open modal
  const handleOpenModal = (name) => {
    setModals({ ...modals, [name]: true });
  };
  // handle Close modal
  const handleCloseModal = (name) => {
    setModals({ ...modals, [name]: false });
    setEditData();
  };

  const handleStatusUpdate = (event) => {
    const payload = { status: event.target.checked, _id: event.target.id };
    dispatch(
      manageGateways({
        method: "PUT",
        payload,
        name: "STATUS",
      })
    );
  };

  // Api Handles
  const handleDeleteGateway = () => {
    dispatch(
      manageGateways({
        method: "DELETE",
        payload: { _id: editData?._id },
        callback: () => handleCloseModal("deleteModal"),
      })
    );
  };

  // useEffect
  useEffect(() => {
    dispatch(getGatewaysList());
  }, [dispatch]);
  return (
    <div className="tracking-wider h-full">
      <Heading title="Payment Gateways" />
      <section className="w-full relative bg-secondary my-2 sm:my-3 rounded shadow">
        <div className="flex flex-wrap gap-5 px-5 py-4 border-b border-gray-700 items-center justify-end">
          <Button
            title={`Add Gateway`}
            event={() => handleOpenModal("formModal")}
          />
        </div>

        {/* table dtaa */}
        <div className="p-4">
          <div className="table-container">
            <table className="w-full down  text-left whitespace-nowrap">
              <thead>
                <tr>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head rounded-tl-lg">
                    Icon
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Resource
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Gateway Type
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Data
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head">
                    Status
                  </th>
                  <th className="p-3 px-4 title-font tracking-wider font-medium text-sm table-head rounded-tr-lg">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-xs relative h-full overflow-y-auto">
                {fetchLoading ? (
                  <tr>
                    <td colSpan={6} className="py-6">
                      <FetchLoader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {gatawaysData?.length === 0 ? (
                      <tr>
                        <td colSpan={5} className="py-6">
                          <NoData />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {gatawaysData?.map((item, i) => {
                          const {
                            _id,
                            resource,
                            params = {},
                            status,
                            icon,
                            gatewayType,
                          } = item;

                          return (
                            <tr
                              key={_id}
                              className={`${i % 2 !== 0 && "table-head"}`}
                            >
                              <td className="px-4 py-1 max-w-[50px]">
                                <TableImage src={icon} />
                              </td>
                              <td className="px-4 py-1">{resource || "-"}</td>
                              <td className="px-4 py-1">
                                {gatewayType || "-"}
                              </td>

                              <td className="px-4 py-1">
                                <div>
                                  {Object.keys(params).map((val) => {
                                    return (
                                      <div className="flex gap-2">
                                        <span className="text-gray-400 text-[11px]">
                                          {val} :{" "}
                                        </span>
                                        <span>{params[val]} </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>

                              <td className="px-4 py-1">
                                <Toggle
                                  _id={_id}
                                  value={status}
                                  handleChange={handleStatusUpdate}
                                />
                              </td>

                              <td className="px-4 text-center relative ">
                                <ShowOption
                                  handleDelete={() => {
                                    setEditData(item);
                                    handleOpenModal("deleteModal");
                                  }}
                                  handleEdit={() => {
                                    setEditData(item);
                                    handleOpenModal("formModal");
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* new gateways */}
      <Modal
        isOpen={modals.formModal}
        title={editData ? "Update Gateways" : "Add Gateways"}
        onClickOutside={() => handleCloseModal("formModal")}
      >
        <NewGateway
          editData={editData}
          handleCloseModal={() => handleCloseModal("formModal")}
        />
      </Modal>

      {modals.deleteModal && (
        <ConfrimationModal
          handleCancel={() => handleCloseModal("deleteModal")}
          handleConfirm={handleDeleteGateway}
          title="Payment Gateway"
          loading={loading}
        />
      )}
    </div>
  );
};

export default Layout(Gateways);
