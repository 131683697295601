import React, { memo } from "react";
import { FiEdit } from "react-icons/fi";
import { FaProductHunt, FaWallet } from "react-icons/fa";
import { BiTrashAlt } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { AiFillEye, AiOutlineTeam } from "react-icons/ai";
import { FaClone } from "react-icons/fa";
import { FaMessage, FaRankingStar, FaTrash } from "react-icons/fa6";
import { HiOutlineClipboard } from "react-icons/hi";
import { MdOutlinePayment } from "react-icons/md";
import { staffRole } from "../layouts/FindPermission";

const ShowOption = (props) => {
  const {
    handleEdit,
    handleDelete,
    handleView,
    handleWallet,
    handleViewTeam,
    handleRank,
    handlePlacement,
    handleDuplicate,
    handleLeadeBoardRank,
    handleResult,
    handlePayment,
    handleMessage,
  } = props;

  const iconStyle = "cursor-pointer bg-gray-700 p-2 text-lg rounded-full";
  return (
    <div className="rounded z-50 text-base items-center right-20 gap-3 flex ">
      {/* Update */}
      {handleEdit && (
        <span title="Update" onClick={handleEdit} className={iconStyle}>
          <FiEdit />
        </span>
      )}

      {/* View */}
      {handleView && (
        <span title="View" onClick={handleView} className={iconStyle}>
          <AiFillEye />
        </span>
      )}

      {/* View Team */}
      {handleViewTeam && (
        <span title="Teams" onClick={handleViewTeam} className={iconStyle}>
          <AiOutlineTeam />
        </span>
      )}

      {/* Delete */}
      {handleDelete && (
        <span
          title="Delete"
          onClick={handleDelete}
          className={`${iconStyle} text-red-500`}
        >
          <FaTrash   />
        </span>
      )}

      {/* update Wallet */}
      {handleWallet && (
        <span title="Wallet" onClick={handleWallet} className={iconStyle}>
          <FaWallet />
        </span>
      )}

      {/* Rank Update */}
      {handleRank && (
        <span title="Update Rank" onClick={handleRank} className={iconStyle}>
          <BsCheck2Circle />
        </span>
      )}

      {/* Placement Points Update */}
      {handlePlacement && (
        <span
          title="Placement Points "
          onClick={handlePlacement}
          className={iconStyle}
        >
          <FaProductHunt />
        </span>
      )}

      {/* Leaderboard Ranks Update */}
      {handleLeadeBoardRank && (
        <span
          title="Leaderboard Rank"
          onClick={handleLeadeBoardRank}
          className={iconStyle}
        >
          <FaRankingStar />
        </span>
      )}  

      {/* Dubplicate Tournament */}
      {handleDuplicate && !staffRole && (
        <span
          title="Duplicate Tournament "
          onClick={handleDuplicate}
          className={iconStyle}
        >
          <FaClone />
        </span>
      )}

      {handleResult && (
        <span
          title="Declare Result"
          onClick={handleResult}
          className={iconStyle}
        >
          <HiOutlineClipboard />
        </span>
      )}

      {handlePayment && (
        <span
          title="Declare Result"
          onClick={handlePayment}
          className={iconStyle}
        >
          <MdOutlinePayment />
        </span>
      )}

      {handleMessage && (
        <span
          title="Send Message"
          onClick={handleMessage}
          className={iconStyle}
        >
          <FaMessage />
        </span>
      )}
    </div>
  );
};

export default memo(ShowOption);
