import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonLoader from "../../../components/ButtonLoader";
import { resourceData } from "../../../redux/actions/authAction";
import { FaTrash } from "react-icons/fa6";
import Button from "../../../components/Button";
import {
  fetchGatewayTypes,
  manageGateways,
} from "../../../redux/actions/gatewaysAction";

const NewGateway = ({ handleCloseModal, editData }) => {
  const dispatch = useDispatch();

  const [formInput, setFormInput] = useState({
    params: { key: "" },
  });
  const { fetchLoading, gatewayTypeData } = useSelector(
    (state) => state.gatewaysReducer
  );
  const { upiResourceList } = useSelector((state) => state.authReducer);

  const handleChange = ({ e, fieldIndex }) => {
    const { name, value } = e.target;

    // Handle the case where `fieldIndex` is provided (i.e., dynamic key/value pairs)
    if (fieldIndex !== undefined) {
      const { params } = formInput;
      const allKeys = Object.keys(params);
      const allValues = Object.values(params);

      // Create a new object to hold updated parameters
      const updatedParams = {};

      // Update the key if the name is "key"
      if (name === "key") {
        allKeys[fieldIndex] = value;
        allKeys.forEach((key, i) => {
          updatedParams[key] = allValues[i];
        });
      } else {
        // Update the value for the current fieldIndex
        allValues[fieldIndex] = value;
        allKeys.forEach((key, i) => {
          updatedParams[key] = allValues[i];
        });
      }

      // Update the formInput with the newly created updatedParams
      setFormInput({
        ...formInput,
        params: updatedParams,
      });
    } else {
      // Handle the case where `fieldIndex` is not provided (i.e., regular form inputs)
      setFormInput({
        ...formInput,
        [name]: value,
      });
    }
  };

  const addNewUPIField = () => {
    setFormInput({
      ...formInput,
      params: { ...formInput?.params, key: "" },
    });
  };

  const handleRemoveUPIField = (fieldIndex) => {
    const { params } = formInput;
    const newParams = Object.keys(params).filter((_, i) => i !== fieldIndex);
    const updatedParams = {};
    newParams.map((item) => {
      updatedParams[item] = params[item];
      return { [item]: params[item] };
    });

    setFormInput({
      ...formInput,
      params: updatedParams,
    });
  };

  // Handle Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      manageGateways({
        method: editData ? "PUT" : "POST",
        payload: formInput,
        callback: handleCloseModal,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchGatewayTypes());
  }, [dispatch]);

  useEffect(() => {
    if (formInput?.gatewayType) {
      dispatch(resourceData({ params: { type: formInput?.gatewayType } }));
    }
  }, [dispatch, formInput?.gatewayType]);

  useEffect(() => {
    if (editData) {
      setFormInput(editData);
    }
  }, [editData]);

  return (
    <form onSubmit={handleSubmit} className="grid gap-3">
      {/* gatewayType */}
      <div className="grid gap-1">
        <label htmlFor="gatewayType" className="text-sm">
          Gateway Type
        </label>
        <select
          name="gatewayType"
          value={formInput?.gatewayType}
          onChange={(e) => handleChange({ e })}
          className="rounded py-1.5 bg-secondary px-2 outline-none border"
        >
          <option value="">Select Type</option>
          {gatewayTypeData?.map((reso) => {
            return <option value={reso}>{reso}</option>;
          })}
        </select>
      </div>

      {/* resource */}
      <div className="grid gap-1">
        <label htmlFor="resource" className="text-sm">
          Resource
        </label>
        <select
          name="resource"
          value={formInput?.resource}
          onChange={(e) => handleChange({ e })}
          className="rounded py-1.5 bg-secondary px-2 outline-none border"
        >
          <option value="">Select Resource</option>
          {upiResourceList.map((reso) => {
            return <option value={reso}>{reso}</option>;
          })}
        </select>
      </div>

      {/* icon */}
      <div className="grid gap-1">
        <label htmlFor="icon" className="text-sm">
          Icon
        </label>
        <input
          autoComplete="off"
          type="url"
          name="icon"
          value={formInput?.icon}
          placeholder="Enter icon"
          onChange={(e) => handleChange({ e })}
          className="rounded py-1.5 px-2 outline-none border"
        />
      </div>

      {/* params */}
      <div className="grid gap-1.5">
        <div className="flex justify-between w-full">
          <span>Params</span>
          <Button
            title="Add New Field"
            className="md:text-xs"
            event={addNewUPIField}
          />
        </div>

        <div className="py-2">
          <table className="w-full text-left whitespace-nowrap">
            <thead>
              <tr>
                <th className="p-2 title-font tracking-wider font-medium text-sm table-head rounded-tl-lg">
                  Key
                </th>
                <th className="p-2 title-font tracking-wider font-medium text-sm table-head">
                  Value
                </th>
                {Object.keys(formInput?.params).length > 1 && (
                  <th className="p-2 title-font tracking-wider font-medium text-sm table-head rounded-tr-lg">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            {Object.keys(formInput?.params)?.map((val, fieldIndex) => {
              return (
                <tr key={fieldIndex}>
                  <td className="p-2">
                    <input
                      autoComplete="off"
                      type="text"
                      name="key"
                      value={val}
                      placeholder="Enter key"
                      onChange={(e) => handleChange({ e, fieldIndex })}
                      className="rounded w-full text-xs bg-secondary p-2 outline-none border"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      autoComplete="off"
                      type="text"
                      name="value"
                      value={formInput?.params[val]}
                      placeholder="Enter value"
                      onChange={(e) => handleChange({ e, fieldIndex })}
                      className="rounded w-full text-xs bg-secondary p-2 outline-none border"
                    />
                  </td>
                  {Object.keys(formInput?.params).length > 1 && (
                    <td className="p-2">
                      <div className=" flex items-end justify-center ">
                        <FaTrash
                          onClick={() => handleRemoveUPIField(fieldIndex)}
                          className="hover:text-red-500 cursor-pointer"
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      {/* Button */}
      <button
        type="submit"
        fetchLoading={fetchLoading}
        className="bg-button justify-center flex items-center cursor-pointer tracking-wider py-2 px-4 mt-2 rounded text-white"
      >
        {fetchLoading ? <ButtonLoader /> : "Submit"}
      </button>
    </form>
  );
};

export default NewGateway;
