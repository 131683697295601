import React, { useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";

const Modal = ({ children, onClickOutside, className, isOpen, title }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  return (
    isOpen && (
      <seciton
        style={{ zIndex: 500 }}
        className="tracking-wider overflow-hidden absolute top-0 items-center flex justify-end left-0 w-full h-screen bg-modal"
      >
        <div
          ref={wrapperRef}
          className={`pb-10 h-full overflow-auto  bg-secondary ${
            className ? className : "w-4/5 md:w-2/3 lg:w-1/2 xl:w-1/3"
          }`}
        >
          {/* Form Head Section */}
          <div className="flex py-3 sticky top-0 left-0 px-5 table-head justify-between items-center">
            <span className="text-color">{title}</span>
            <MdClose
              className="text-xl cursor-pointer"
              onClick={onClickOutside}
            />
          </div>

          {/* Form Content Section */}
          <div className="p-4">{children}</div>
        </div>
      </seciton>
    )
  );
};

export default Modal;
