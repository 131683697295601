import React from "react";

const NoData = ({ text }) => {
  return (
    <div className="text-center text-base w-full py-6">
      No {text || "Record"} Found
    </div>
  );
};

export default NoData;
