import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../components/ButtonLoader";
import { resourceData } from "../../../redux/actions/authAction";
import { manageSMSServices } from "../../../redux/actions/smsServiceAction";

const NewSmsService = ({ handleCloseModal, editData }) => {
  const dispatch = useDispatch();
  const [formInput, setFormInput] = useState({});
  const { loading } = useSelector((state) => state.smsServiceReducer);
  const [params, setparams] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
  };

  // Handle Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      manageSMSServices({
        method: editData ? "PUT" : "POST",
        payload: formInput,
        callback: handleCloseModal,
      })
    );
  };

  useEffect(() => {
    dispatch(resourceData({ params: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (editData) {
      setFormInput(editData);
    }
  }, [editData]);

  return (
    <form onSubmit={handleSubmit} className="grid gap-3">
      {/* name */}
      <div className="grid gap-1">
        <label htmlFor="name" className="text-sm">
          Name
        </label>
        <input
          autoComplete="off"
          type="text"
          name="name"
          value={formInput?.name}
          placeholder="Enter name"
          onChange={handleChange}
          className="rounded py-1.5 px-2 outline-none border"
        />
      </div>

      {/* Button */}
      <button
        type="submit"
        loading={loading}
        className="bg-button justify-center flex items-center cursor-pointer tracking-wider py-2 px-4 mt-2 rounded text-white"
      >
        {loading ? <ButtonLoader /> : "Submit"}
      </button>
    </form>
  );
};

export default NewSmsService;
