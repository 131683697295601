import Logo from "../assets/Logo.png";
import React, { useState } from "react";
import { HiOutlineMenu } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { MdOutlineNotificationsActive } from "react-icons/md";
import UpdateProfile from "./Components/UpdateProfile";
import UpdatePassword from "./Components/UpdatePassword";
import Modal from "../components/Modal";
import toast from "react-hot-toast";

const Header = ({ handleToggle, toggle }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState();
  const [updateProfile, setUpdateProfile] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);

  const data = JSON.parse(localStorage.getItem("Admin"));
  const { email, role, staffRole, imageUrl, profilePicture } = data;

  // handleOpenModal
  const handleOpenModal = (value) =>
    setShowModal(showModal === value ? "" : value);

  return (
    <div className="rounded-md p-3 sm:p-4 bg-secondary shadow tracking-wider flex items-center">
      <HiOutlineMenu
        onClick={handleToggle}
        className=" text-2xl sm:text-3xl cursor-pointer text-color"
      />

      {/* Logo */}
      <img
        src={Logo}
        alt="ApkiStore"
        className={`w-24 ${toggle ? "flex lg:hidden" : "hidden lg:flex"
          }  ml-4 sm:ml-3`}
      />

      {/* Icons */}
      <section className="flex sm:relative items-center ml-auto gap-3 sm:gap-5">
        <button
          type="button"
          onClick={() => navigate("/notifications")}
          className="icon-bg flex justify-center items-center text-2xl font-bold text-color rounded-full p-1.5 sm:p-2.5 cursor-pointer"
        >
          <MdOutlineNotificationsActive />
        </button>

        {/* Profile */}
        <section className="flex gap-2 sm:gap-3 items-center">
          <button
            onClick={() => handleOpenModal("Profile")}
            class="relative z-10 flex items-center p-2.5 text-sm text-gray-200 icon-bg border border-transparent rounded-md focus:ring-opacity-40 dark:focus:ring-opacity-40"
          >
            <span class="mx-1 capitalize">{staffRole?.name || role}</span>
            <svg
              class="w-5 h-5 mx-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          {/* <div className="sm:flex items-end hidden flex-col">
            <span className="font-bold text-color capitalize">
              {staffRole?.name || role}
            </span>
            <span className="text-sm ">{email}</span>
          </div>
          <div className="icon-bg z-10 flex justify-center items-center text-3xl font-bold w-9 h-9 sm:w-10 sm:h-10 text-color rounded-full">
            <img
              onClick={() => handleOpenModal("Profile")}
              src={`${imageUrl}${profilePicture}`}
              className="w-full h-full cursor-pointer object-cover object-top rounded-full"
            />
          </div> */}

          {/* Profile Drawer */}
          {showModal === "Profile" && (
            <div class="absolute border-gray-700 border top-20 sm:top-16 right-3 sm:right-0 z-20 w-60 py-2 mt-2 overflow-hidden bg-secondary rounded-md shadow-xl">
              <div
                onClick={() => {
                  if (staffRole) {
                    navigate("/setting/profile");
                    handleOpenModal("Profile");
                  }
                }}
                class="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-300 transform hover:bg-gray-600 cursor-pointer"
              >
                <img
                  class="flex-shrink-0 object-cover mx-1 rounded-full w-9 h-9"
                  src={`${imageUrl}${profilePicture}`}
                  alt={staffRole?.name || role}
                />
                <div class="mx-1">
                  <h1 class="text-sm capitalize font-semibold text-gray-200">
                    {staffRole?.name || role}
                  </h1>
                  <p class="text-sm overflow-clip break-words text-break text-gray-400">
                    {email}
                  </p>
                </div>
              </div>

              <hr class="border-gray-700 " />

              <div
                onClick={() => {
                  setUpdateProfile(true);
                  handleOpenModal("Profile");
                }}
                class="block px-4 py-3 text-sm text-gray-300 capitalize transition-colors duration-300 transform hover:bg-gray-600 cursor-pointer"
              >
                update profile
              </div>

              <div
                onClick={() => {
                  setUpdatePassword(true);
                  handleOpenModal("Password");
                }}
                class="block px-4 py-3 text-sm text-gray-300 capitalize transition-colors duration-300 transform hover:bg-gray-600 cursor-pointer"
              >
                update Password
              </div>

              <hr class="border-gray-700 " />

              <div
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                  toast.success("Log Out Success");
                }}
                class="block px-4 py-3 text-sm text-gray-300 capitalize transition-colors duration-300 transform hover:bg-gray-600 cursor-pointer"
              >
                Sign Out
              </div>
            </div>
          )}

          {/* {showModal === "Profile" && (
            <div className="rounded p-3 gap-1.5 w-44 z-30 absolute top-14 sm:top-16 right-3   bg-secondary text-xs grid text-left shadow">
              {staffRole && (
                <span
                  onClick={() => {
                    navigate("/setting/profile");
                    handleOpenModal("Profile");
                  }}
                  className="cursor-pointer"
                >
                  My Profile
                </span>
              )}

              <span
                onClick={() => {
                  setUpdateProfile(true);
                  handleOpenModal("Profile");
                }}
                className="cursor-pointer"
              >
                Update Profile
              </span>

              <span
                onClick={() => {
                  setUpdatePassword(true);
                  handleOpenModal("Password");
                }}
                className="cursor-pointer"
              >
                Update Password
              </span>
              <span
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                  toast.success("Log Out Success");
                }}
                className="cursor-pointer"
              >
                Sign Out
              </span>
            </div>
          )} */}
        </section>
      </section>

      {/* Update Profile */}
      <Modal
        isOpen={updateProfile}
        title={"Update Profile"}
        onClickOutside={() => setUpdateProfile(false)}
      >
        <UpdateProfile
          data={data}
          handleCloseModal={() => setUpdateProfile(false)}
        />
      </Modal>

      {/* Update Password */}
      <Modal
        isOpen={updatePassword}
        title={"Update Password"}
        onClickOutside={() => setUpdatePassword(false)}
      >
        <UpdatePassword handleCloseModal={() => setUpdatePassword(false)} />
      </Modal>
    </div>
  );
};

export default Header;
