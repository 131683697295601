import {
  CREATE_STAFFROLE,
  CREATE_STAFFROLE_FAIL,
  CREATE_STAFFROLE_SUCCESS,
  CREATE_STAFFROLL,
  GET_PERMISSIONS_LIST,
  GET_PERMISSIONS_LIST_FAIL,
  GET_PERMISSIONS_LIST_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_FAIL,
  GET_ROLE_LIST_SUCCESS,
  GET_STAFF_LIST,
  GET_STAFF_LIST_FAIL,
  GET_STAFF_LIST_SUCCESS,
  REMOVE_STAFFROLE,
  REMOVE_STAFFROLE_FAIL,
  REMOVE_STAFFROLE_SUCCESS,
  UPDATE_STAFFROLE,
  UPDATE_STAFFROLE_FAIL,
  UPDATE_STAFFROLE_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  staff: {},
  role: {},
  loading: false,
  permissionsData: {},
  allPermissions: []
};

const staffRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_LIST:
      return { ...state, fetchLoading: true };
    case GET_ROLE_LIST_SUCCESS:
      return { ...state, fetchLoading: false, role: action.payload };
    case GET_ROLE_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case GET_PERMISSIONS_LIST:
      return { ...state, fetchLoading: true };
    case GET_PERMISSIONS_LIST_SUCCESS:
      const allPermissions = action.payload.permissions.permissions

      return { ...state, fetchLoading: false, permissionsData: action.payload, allPermissions };
    case GET_PERMISSIONS_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case GET_STAFF_LIST:
      return { ...state, fetchLoading: true };
    case GET_STAFF_LIST_SUCCESS:
      return { ...state, fetchLoading: false, staff: action.payload };
    case GET_STAFF_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case CREATE_STAFFROLE:
      return { ...state, loading: true };
    case CREATE_STAFFROLE_SUCCESS:
      return { ...state, loading: false };
    case CREATE_STAFFROLE_FAIL:
      return { ...state, loading: false };

    case REMOVE_STAFFROLE:
      return { ...state, loading: true };
    case REMOVE_STAFFROLE_SUCCESS:
      return { ...state, loading: false };
    case REMOVE_STAFFROLE_FAIL:
      return { ...state, loading: false };

    case UPDATE_STAFFROLE:
      return { ...state, loading: true };
    case UPDATE_STAFFROLE_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_STAFFROLE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default staffRoleReducer;
