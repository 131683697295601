import { API_REQUEST } from "../../utils/interceptor";
import {
  create_permissions,
  get_permissions_result,
  get_roles_result,
  get_staff_result,
  new_role,
  new_staff,
  remove_role,
  remove_staff,
  update_role,
  update_staff,
  update_status_role,
  update_status_staff,
} from "../../utils/endpoints";
import {
  CREATE_STAFFROLE,
  CREATE_STAFFROLE_FAIL,
  CREATE_STAFFROLE_SUCCESS,
  GET_PERMISSIONS_LIST,
  GET_PERMISSIONS_LIST_FAIL,
  GET_PERMISSIONS_LIST_SUCCESS,
  GET_ROLE_LIST,
  GET_ROLE_LIST_FAIL,
  GET_ROLE_LIST_SUCCESS,
  GET_STAFF_LIST,
  GET_STAFF_LIST_FAIL,
  GET_STAFF_LIST_SUCCESS,
  GIVE_PERMISSIONS,
  GIVE_PERMISSIONS_FAIL,
  GIVE_PERMISSIONS_SUCCESS,
  REMOVE_STAFFROLE,
  REMOVE_STAFFROLE_FAIL,
  REMOVE_STAFFROLE_SUCCESS,
  UPDATE_STAFFROLE,
  UPDATE_STAFFROLE_FAIL,
  UPDATE_STAFFROLE_STATUS,
  UPDATE_STAFFROLE_STATUS_FAIL,
  UPDATE_STAFFROLE_STATUS_SUCCESS,
  UPDATE_STAFFROLE_SUCCESS,
} from ".";

// get roles list
export const getRolesList = (params) => async (dispatch) => {
  dispatch({ type: GET_ROLE_LIST });
  try {
    const response = await API_REQUEST({
      url: get_roles_result,
      method: "GET",
      params,
    });
    const { data } = response;
    dispatch({ type: GET_ROLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ROLE_LIST_FAIL });
  }
};

// get staff list
export const getStaffList = (params) => async (dispatch) => {
  dispatch({ type: GET_STAFF_LIST });
  try {
    const response = await API_REQUEST({
      url: get_staff_result,
      method: "GET",
      params,
    });
    const { data } = response;
    dispatch({ type: GET_STAFF_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_STAFF_LIST_FAIL });
  }
};

// create staff role
export const createStaffRole =
  (payload, callback, isRole = false) =>
  async (dispatch) => {
    dispatch({ type: CREATE_STAFFROLE });
    try {
      const response = await API_REQUEST({
        url: isRole ? new_role : new_staff,
        method: "POST",
        data: payload,
      });
      const { data } = response;
      callback();
      dispatch(isRole ? getRolesList() : getStaffList());
      dispatch({ type: CREATE_STAFFROLE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CREATE_STAFFROLE_FAIL });
    }
  };

// update staff role
export const updateStaffRole =
  (id, payload, callback, isRole = false) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_STAFFROLE });
    try {
      const response = await API_REQUEST({
        url: `${isRole ? update_role : update_staff}/${id}`,
        method: "POST",
        data: payload,
      });
      const { data } = response;
      callback();
      dispatch(isRole ? getRolesList() : getStaffList());
      dispatch({ type: UPDATE_STAFFROLE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_STAFFROLE_FAIL });
    }
  };

// update staff role status
export const updateStaffRoleStatus =
  (id, payload, isRole = false) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_STAFFROLE_STATUS });
    try {
      const response = await API_REQUEST({
        url: `${isRole ? update_status_role : update_status_staff}/${id}`,
        method: "POST",
        data: payload,
      });
      const { data } = response;
      dispatch(isRole ? getRolesList() : getStaffList());
      dispatch({ type: UPDATE_STAFFROLE_STATUS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_STAFFROLE_STATUS_FAIL });
    }
  };

// remove staff role
export const removeStaffRole =
  (id, callback, isRole = false) =>
  async (dispatch) => {
    dispatch({ type: REMOVE_STAFFROLE });
    try {
      const response = await API_REQUEST({
        url: `${isRole ? remove_role : remove_staff}/${id}`,
        method: "GET",
      });
      const { data } = response;
      callback();
      dispatch(isRole ? getRolesList() : getStaffList());
      dispatch({ type: REMOVE_STAFFROLE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: REMOVE_STAFFROLE_FAIL });
    }
  };

// view permissions of role
export const viewPermissions = (roleId) => async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS_LIST });
  try {
    const response = await API_REQUEST({
      url: `${get_permissions_result}/${roleId}`,
      method: "GET",
    });
    const { data } = response;
    dispatch({ type: GET_PERMISSIONS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_PERMISSIONS_LIST_FAIL });
  }
};

// create permissions to role
export const givePermissions =
  (roleId, payload, callback) => async (dispatch) => {
    dispatch({ type: GIVE_PERMISSIONS });
    try {
      const response = await API_REQUEST({
        url: `${create_permissions}/${roleId}`,
        method: "POST",
        data: payload,
      });
      const { data } = response;
      callback();
      dispatch({ type: GIVE_PERMISSIONS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GIVE_PERMISSIONS_FAIL });
    }
  };
