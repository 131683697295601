import {
  FETCH_GATEWAYS,
  FETCH_GATEWAYS_FAIL,
  FETCH_GATEWAYS_SUCCESS,
  FETCH_GATEWAYS_TYPES,
  FETCH_GATEWAYS_TYPES_FAIL,
  FETCH_GATEWAYS_TYPES_SUCCESS,
  MANAGE_GATEWAYS,
  MANAGE_GATEWAYS_FAIL,
  MANAGE_GATEWAYS_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  loading: false,
  gatawaysData: [],
  gatewayTypeData: [],
};

const gatewaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GATEWAYS:
      return { ...state, fetchLoading: true };
    case FETCH_GATEWAYS_SUCCESS:
      return { ...state, fetchLoading: false, gatawaysData: action.payload };
    case FETCH_GATEWAYS_FAIL:
      return { ...state, fetchLoading: false };

    case MANAGE_GATEWAYS:
      return { ...state, loading: true };
    case MANAGE_GATEWAYS_SUCCESS:
      return { ...state, loading: false };
    case MANAGE_GATEWAYS_FAIL:
      return { ...state, loading: false };

    case FETCH_GATEWAYS_TYPES:
      return { ...state, fetchLoading: true };
    case FETCH_GATEWAYS_TYPES_SUCCESS:
      return { ...state, fetchLoading: false, gatewayTypeData: action.payload };
    case FETCH_GATEWAYS_TYPES_FAIL:
      return { ...state, fetchLoading: false };

    default:
      return state;
  }
};

export default gatewaysReducer;
