import {
  CREATE_BET_AMOUNT,
  CREATE_BET_AMOUNT_FAIL,
  CREATE_BET_AMOUNT_SUCCESS,
  DECLARE_DISPUTE_RESULT,
  DECLARE_DISPUTE_RESULT_FAIL,
  DECLARE_DISPUTE_RESULT_SUCCESS,
  GET_BET_AMOUNTS,
  GET_BET_AMOUNTS_FAIL,
  GET_BET_AMOUNTS_SUCCESS,
  GET_DISPUTES,
  GET_DISPUTES_FAIL,
  GET_DISPUTES_SUCCESS,
  PLAYER_LIST,
  PLAYER_LIST_FAIL,
  PLAYER_LIST_SUCCESS,
  TDM_LOG_LIST,
  TDM_LOG_LIST_FAIL,
  TDM_LOG_LIST_SUCCESS,
  TRANSACTION_LIST,
  TRANSACTION_LIST_FAIL,
  TRANSACTION_LIST_SUCCESS,
  UPDATE_BET_AMOUNT,
  UPDATE_BET_AMOUNT_FAIL,
  UPDATE_BET_AMOUNT_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  betAmountData: {},
  disputeData: {},
  logsData: {},
  tdmTransactions: {},
  loading: false,
  playersList: {},
};

const tdmReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BET_AMOUNTS:
      return { ...state, fetchLoading: true };
    case GET_BET_AMOUNTS_SUCCESS:
      return { ...state, fetchLoading: false, betAmountData: action.payload };
    case GET_BET_AMOUNTS_FAIL:
      return { ...state, fetchLoading: false };

    case GET_DISPUTES:
      return { ...state, fetchLoading: true };
    case GET_DISPUTES_SUCCESS:
      return { ...state, fetchLoading: false, disputeData: action.payload };
    case GET_DISPUTES_FAIL:
      return { ...state, fetchLoading: false };

    case TDM_LOG_LIST:
      return { ...state, fetchLoading: true };
    case TDM_LOG_LIST_SUCCESS:
      return { ...state, fetchLoading: false, logsData: action.payload };
    case TDM_LOG_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case TRANSACTION_LIST:
      return { ...state, fetchLoading: true };
    case TRANSACTION_LIST_SUCCESS:
      return { ...state, fetchLoading: false, tdmTransactions: action.payload };
    case TRANSACTION_LIST_FAIL:
      return { ...state, fetchLoading: false };

    case CREATE_BET_AMOUNT:
      return { ...state, loading: true };
    case CREATE_BET_AMOUNT_SUCCESS:
      return { ...state, loading: false };
    case CREATE_BET_AMOUNT_FAIL:
      return { ...state, loading: false };

    case UPDATE_BET_AMOUNT:
      return { ...state, loading: true };
    case UPDATE_BET_AMOUNT_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_BET_AMOUNT_FAIL:
      return { ...state, loading: false };

    case DECLARE_DISPUTE_RESULT:
      return { ...state, loading: true };
    case DECLARE_DISPUTE_RESULT_SUCCESS:
      return { ...state, loading: false };
    case DECLARE_DISPUTE_RESULT_FAIL:
      return { ...state, loading: false };

    case PLAYER_LIST:
      return { ...state, fetchLoading: true };
    case PLAYER_LIST_SUCCESS:
      return { ...state, fetchLoading: false, playersList: action.payload };
    case PLAYER_LIST_FAIL:
      return { ...state, fetchLoading: false };

    default:
      return state;
  }
};

export default tdmReducer;
