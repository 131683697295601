export function calculateAge(dob) {
  // Parse the provided date of birth string into a Date object
  const birthDate = new Date(dob);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust the age if the birthday hasn't occurred yet this year
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return `${age} Years`;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getStartAndEndDates = (filterType) => {
  const currentDate = new Date();

  switch (filterType) {
    case "This Week":
      // Get the current day of the week (0 is Sunday, 1 is Monday, etc.)
      const dayOfWeek = currentDate.getDay();
      // Calculate the number of days to subtract to get the last Monday
      const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      return {
        fromDate: formatDate(
          new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - daysToMonday
          )
        ),
        toDate: formatDate(currentDate),
      };

    case "This Month":
      return {
        fromDate: formatDate(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        ),
        toDate: formatDate(currentDate),
      };

    case "Last 3 Months":
      const last3MonthsStartDate = new Date(currentDate);
      last3MonthsStartDate.setMonth(currentDate.getMonth() - 2); // Subtract 2 because getMonth is zero-based
      last3MonthsStartDate.setDate(1); // Set to the first day of the month
      return {
        fromDate: formatDate(last3MonthsStartDate),
        toDate: formatDate(currentDate),
      };

    case "This Year":
      return {
        fromDate: formatDate(new Date(currentDate.getFullYear(), 0, 1)),
        toDate: formatDate(currentDate),
      };

    default:
      return null;
  }
};

// Example usage:
const dates = getStartAndEndDates("This Week");

export const filterTypes = [
  {
    label: "This Month",
    value: getStartAndEndDates("This Month"),
  },
  {
    label: "This Week",
    value: getStartAndEndDates("This Week"),
  },
  {
    label: "Last 3 Months",
    value: getStartAndEndDates("Last 3 Months"),
  },
  {
    label: "This Year",
    value: getStartAndEndDates("This Year"),
  },
];

export const acceptImageFileType = ".png, .jpeg, .jpg";
