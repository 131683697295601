import ButtonLoader from "./ButtonLoader";
import React, { useEffect, useRef, useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getTeamsStageWise } from "../redux/actions/leagueAction";

const ConfrimationModal = (props) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [sendNotificationStatus, setSendNotificationStatus] = useState(false);
  const { teamsStageWise } = useSelector((state) => state.leagueReducer);
  const { result } = teamsStageWise;
  const {
    title,
    handleCancel,
    handleConfirm,
    loading,
    isVerify = false,
    isCreate = false,
    isSettle = false,
    activeTourStageId = null,
    isPass = false,
  } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCancel]);

  useEffect(() => {
    isSettle &&
      dispatch(
        getTeamsStageWise(activeTourStageId, { sort: "tt", order: "desc" })
      );
  }, [dispatch, isSettle]);

  return (
    <div className="tracking-wider overflow-hidden absolute z-50 top-0 items-start flex justify-center left-0 w-full h-screen bg-modal">
      <div
        ref={wrapperRef}
        className=" px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-select rounded-lg shadow-xl rtl:text-right sm:my-8 sm:align-middle sm:max-w-sm md:max-w-md w-full m-4 sm:m-0 sm:p-6"
      >
        {/* Icon */}
        <div className="flex items-center justify-center">
          {isVerify ? (
            <IoMdCheckmarkCircleOutline className="text-color text-4xl" />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-9 h-9 text-color "
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              />
            </svg>
          )}
        </div>

        {/* Description */}
        <div className="mt-2 text-center">
          {isSettle ? (
            <h3
              className=" font-medium leading-6 text-color capitalize "
              id="modal-title"
            >
              Settle Stage Amount
            </h3>
          ) : (
            <h3
              className=" font-medium leading-6 text-color capitalize "
              id="modal-title"
            >
              {isVerify ? (isPass ? "" : "Verify") : isCreate ? "" : "Remove"}{" "}
              {title}
            </h3>
          )}
          {isSettle ? (
            <p className="mt-1 text-sm text-gray-500 ">
              Do you want to settle stage amount ?
            </p>
          ) : (
            <p className="mt-1 text-sm text-gray-500 ">
              {isVerify
                ? `Do you want to ${isPass ? "" : "verify user's"} ${title}`
                : isCreate
                ? `Are you sure want to ${title}`
                : `Are you sure? ${title} will be remove.`}
            </p>
          )}
        </div>

        {/* send notification on create tournament */}
        {isCreate && (
          <div className="flex p-3 pb-0 items-center gap-2">
            <input
              type="checkbox"
              id="sendNotificationStatus"
              checked={sendNotificationStatus}
              onChange={(event) =>
                setSendNotificationStatus(event.target.checked)
              }
            />
            <label htmlFor="sendNotificationStatus" className="cursor-pointer">
              Send notification
            </label>
          </div>
        )}

        {/* Buttons  */}
        <div className="flex mt-5 sm:flex-row flex-col sm:items-center gap-3 ">
          <button
            onClick={handleCancel}
            type="button"
            className="border-none bg-gray-600 w-full justify-center text-sm flex items-center gap-1 cursor-pointer tracking-wider p-2 sm:px-4 rounded text-white"
          >
            Cancel
          </button>

          <button
            onClick={() =>
              isCreate ? handleConfirm(sendNotificationStatus) : handleConfirm()
            }
            type="button"
            disabled={loading}
            className="bg-button border-none  w-full justify-center text-sm flex items-center gap-1 cursor-pointer tracking-wider p-2 sm:px-4 rounded text-white"
          >
            {isSettle ? (
              "Settle Amount"
            ) : (
              <>
                {loading ? (
                  <ButtonLoader />
                ) : isVerify ? (
                  ` ${isPass ? "" : "Verify"} ${title}`
                ) : isCreate ? (
                  "Re-Create"
                ) : (
                  "Delete"
                )}
              </>
            )}
          </button>
        </div>

        {isSettle && (
          <table className="mt-5 w-full">
            <thead>
              <tr className="text-xs">
                <td className="px-2.5 table-head py-1.5 rounded-tl-lg">Rank</td>
                <td className="px-2.5 table-head py-1.5">Team</td>
                <td className="px-2.5 table-head py-1.5">KP</td>
                <td className="px-2.5 table-head py-1.5">PP</td>
                <td className="px-2.5 table-head py-1.5 rounded-tr-lg">TT</td>
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                return (
                  <tr className="text-xs">
                    <td className="px-2.5 py-1.5">{index + 1}</td>
                    <td className="px-2.5 py-1.5">{item?.teams?.name}</td>
                    <td className="px-2.5 py-1.5">{item?.kp}</td>
                    <td className="px-2.5 py-1.5">{item?.pp}</td>
                    <td className="px-2.5 py-1.5">{item?.tt}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ConfrimationModal;
